<template>
  <div>
    <!-- 导航 -->
    <HomePageTop />

    <div class="userCenter">
      <!-- 用户粗略信息 -->
      <!--            <div class="userindexhead">-->
      <!--              <userInfo :myPage="myPage"></userInfo>-->
      <!--            </div>-->
      <!-- 内容 -->
      <div class="userindexBody">
        <!--        左侧菜单及用户信息展示-->
        <div class="leftUserMessageContainer">
          <!--用户信息展示 -->
          <div class="userMessageHeaderContainer">
            <div class="userMessageContainerTop">
              <el-upload
                class="upload-demo"
                action="https://laravel.rakumart.es/api/common/uploadFile"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                name="file"
                accept=".jpg,.jpeg,.png"
                :before-upload="beforeAvatarUpload"
              >
                <div class="showDow">{{ $fanyi("更改头像") }}</div>
                <img
                  class="userHeadImg"
                  :src="userInfo.head_img"
                  alt=""
                  v-if="userInfo.head_img"
                />
                <img
                  class="userHeadImg"
                  src="../../assets/base-user.png"
                  alt=""
                  v-else
                />
              </el-upload>

              <div class="userName">
                <div class="top">
                  <div>{{ $fanyi("你好") }},</div>
                  <div>{{ userInfo.user_name }}</div>
                </div>
                <div class="bottom">{{ $fanyi("这是你的控制面板") }}</div>
              </div>
            </div>
            <div
              class="userMessageContainerBottom"
              @click="$router.push({ name: 'inform' })"
            >
              <div class="userMessageBottomContainerLeft">
                <img
                  src="../../assets/user-img/menu/ALERT.svg"
                  style="width: 20px; height: 20px"
                />
                <div style="font-size: 14px">{{ $fanyi("新消息") }}</div>
              </div>
              <div class="userMessageBottomContainerRight">
                {{ count }}
              </div>
            </div>
          </div>
          <!-- 菜单栏 -->
          <div class="menuBar">
            <div v-for="(item, index) in activeMenu" :key="item.id">
              <span
                :class="
                  item.activeStatus === true ? 'activeMenuTitle' : 'menuTitle'
                "
                @click="updateSelectMenu(item.name, 1)"
                v-if="item.name === $fanyi('首页')"
                ><img
                  :src="
                    item.activeStatus === true ? item.activeImgUrl : item.imgUrl
                  "
                  alt=""
                  style="margin-right: 10px"
                />{{ item.name }}</span
              >
              <span
                v-else
                :class="
                  item.activeStatus === true ? 'activeMenuTitle' : 'menuTitle'
                "
                @click="updateSelectMenu(item.name, 2)"
                ><img
                  :src="
                    item.activeStatus === true ? item.activeImgUrl : item.imgUrl
                  "
                  alt=""/>
                {{ item.name }}
                <i
                  class="el-submenu__icon-arrow el-icon-arrow-right el-icon-arrow-down"
                  :class="{
                    beDown: item.downIconStatus === true,
                  }"
                ></i
              ></span>
              <!--              <span v-else class="menuTitle">{{ item.name }} <i class="el-submenu__icon-arrow el-icon-arrow-down"></i></span>-->
              <div
                class="menuChildrenList"
                v-if="item.arr != undefined"
                :class="{ close: item.downIconStatus != true }"
                :style="'height:' + item.arr.length * 58 + 'px'"
              >
                <div
                  v-for="(items, childrenIndex) in item.arr"
                  :key="items.id"
                  class="rowAndCenter"
                  @click="$router.push({ name: items.url, query: items.query })"
                >
                  <div
                    v-if="items.count != undefined"
                    :class="
                      items.activeStatus
                        ? 'activeMenuChildrenContainer rowAndCenter'
                        : 'noActiveMenuChildrenContainer rowAndCenter'
                    "
                    @click="updateSelectChildrenMenu(index, childrenIndex)"
                  >
                    <div class="childrenName">{{ items.name }}</div>
                    <div
                      class="orderCountContainer"
                      v-if="
                        items.count == 1 && $store.state.leftSidebarNum[0].num
                      "
                    >
                      {{ $store.state.leftSidebarNum[0].num }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="
                        items.count == 2 && $store.state.leftSidebarNum[1].num
                      "
                    >
                      {{ $store.state.leftSidebarNum[1].num }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="
                        items.count == 3 && $store.state.leftSidebarNum[2].num
                      "
                    >
                      {{ $store.state.leftSidebarNum[2].num }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="
                        items.count == 4 && $store.state.leftSidebarNum[3].num
                      "
                    >
                      {{ $store.state.leftSidebarNum[3].num }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="
                        items.count == 5 && $store.state.leftSidebarNum[4].num
                      "
                    >
                      {{ $store.state.leftSidebarNum[4].num }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="
                        items.count == 6 &&
                          $store.state.sidebarNumList['发票']['历史发票']
                      "
                    >
                      {{ $store.state.sidebarNumList["发票"]["历史发票"] }}
                    </div>
                    <div
                      class="orderCountContainer"
                      v-else-if="items.count == 7 && favoriteGoodsCount"
                    >
                      {{ favoriteGoodsCount }}
                    </div>
                  </div>
                  <div
                    v-else
                    :class="
                      items.activeStatus
                        ? 'activeMenuChildrenContainer rowAndCenter'
                        : 'noActiveMenuChildrenContainer rowAndCenter'
                    "
                    @click="updateSelectChildrenMenu(index, childrenIndex)"
                  >
                    <div class="childrenName">{{ items.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="contentBar">
          <div class="header">
            <div class="left">
              <div class="title">{{ $fanyi("账户信息摘要") }}</div>
              <div class="content">
                <div class="top">
                  <div
                    class="left"
                    style="margin-right: 20px"
                    @click="
                      $router.push({
                        name: 'userOrder',
                        query: { status: 'offer' },
                      });
                      listenSignStatus(1);
                    "
                  >
                    <div>
                      <countTo
                        :startVal="countNumInfo.orderOffer"
                        :endVal="newCountNumInfo.orderOffer"
                        :duration="3000"
                      ></countTo>
                    </div>
                    <div>{{ $fanyi("订单待处理") }}</div>
                  </div>
                  <div
                    class="right"
                    @click="
                      $router.push({
                        name: 'warehouse',
                        query: { status: 'orderInRAKUMARTWarehouse' },
                      });
                      listenSignStatus(1);
                    "
                  >
                    <div>
                      <countTo
                        :startVal="countNumInfo.upComplete"
                        :endVal="newCountNumInfo.upComplete"
                        :duration="3000"
                      ></countTo>
                    </div>
                    <div>{{ $fanyi("上架完了订单数") }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="bottomLeft">
                    <div class="level">
                      <div>
                        <img
                          :src="
                            require('../../assets/newImg/vipIcon/maber' +
                              userInfo.level_name +
                              '.png')
                          "
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="levelMessage">
                      <div class="levelMessageTop">
                        {{ $fanyi("手续费比例") }}
                        {{ Math.round(userInfo.service_rate * 100) }}%
                      </div>
                      <div class="levelMessageCenter">
                        <div
                          :style="'width:' + userInfo.next_level_difference"
                        ></div>
                      </div>
                      <el-link type="primary" @click="poundagePageJump">{{
                        $fanyi("手续费是如何计算的？")
                      }}</el-link>
                    </div>
                  </div>
                  <div
                    class="cuponesNumberContainer"
                    @click="$router.push({ name: 'coupon' })"
                  >
                    <div>{{ $fanyi("优惠券") }}</div>
                    <div>{{ userInfo.coupon_number }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="title">{{ $fanyi("重点订单信息") }}</div>
              <div class="content">
                <div class="orderNumberContainer greenBackground">
                  <div style="display: flex; align-items: center">
                    <div
                      class="numberBackground"
                      style="background-color: #2ec353"
                    >
                      <countTo
                        :startVal="countNumInfo.orderWaitPay"
                        :endVal="newCountNumInfo.orderWaitPay"
                        :duration="3000"
                      ></countTo>
                    </div>
                    <div class="text">{{ $fanyi("需要付款的订单") }}</div>
                    <div class="icon" style="background-color: #2ec353"></div>
                  </div>
                  <div
                    class="manageContainer"
                    @click="
                      $router.push({
                        name: 'userOrder',
                        query: { status: 'obligation' },
                      });
                      listenSignStatus(1);
                    "
                  >
                    <div class="text">{{ $fanyi("管理") }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="orderNumberContainer blueBackground">
                  <div style="display: flex; align-items: center">
                    <div
                      class="numberBackground"
                      style="background-color: #006dbb"
                    >
                      <countTo
                        :startVal="countNumInfo.porderOffer"
                        :endVal="newCountNumInfo.porderOffer"
                        :duration="3000"
                      ></countTo>
                    </div>
                    <div class="text">{{ $fanyi("需要付款的配送单") }}</div>
                    <div class="icon" style="background-color: #006dbb"></div>
                  </div>
                  <div
                    class="manageContainer"
                    @click="
                      $router.push({
                        name: 'warehouse',
                        query: {
                          status: 'internationalFreightToBePaidShippingBill',
                        },
                      });
                      listenSignStatus(1);
                    "
                  >
                    <div class="text">{{ $fanyi("管理") }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div
                  class="orderNumberContainer yellowBackground"
                  style="margin-bottom: 0"
                >
                  <div style="display: flex; align-items: center">
                    <div
                      class="numberBackground"
                      style="background-color: #ffba4c"
                    >
                      <countTo
                        :startVal="countNumInfo.porderForeignTransport"
                        :endVal="newCountNumInfo.porderForeignTransport"
                        :duration="3000"
                      ></countTo>
                    </div>
                    <div class="text">{{ $fanyi("西班牙运输中的订单") }}</div>
                    <div class="icon" style="background-color: #ffba4c"></div>
                  </div>
                  <div
                    class="manageContainer"
                    @click="
                      $router.push({
                        name: 'wuliuStatus',
                        query: { status: 'deliveryInTransit' },
                      });
                      listenSignStatus(1);
                    "
                  >
                    <div class="text">{{ $fanyi("管理") }}</div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-view
            :myPage="myPage"
            @signStatusVerdict="listenSignStatus"
          ></router-view>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import countTo from "vue-count-to";
import router from "@/router";

export default {
  data() {
    return {
      tableData: [],
      isRouterAlive: true,
      // activeMenu:[],
      UserInfo: {},
      count: 0,
      favoriteGoodsCount: 0, //收藏商品的数量
      warehouseOrderNum: 0,
      defaultOpeneds: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
      ],
      shopLoading: true,
      activeMenu: [
        {
          id: "1",
          name: this.$fanyi("首页"),
          url: "userIndex",
          activeStatus: false,
          downIconStatus: false,
          imgUrl: require("../../assets/user-img/menu/INICIO.svg"),
          activeImgUrl: require("../../assets/user-img/menu/INICIO_1.svg"),
        },
        {
          id: "2",
          name: this.$fanyi("订单管理"),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require("../../assets/user-img/menu/GESTIONDEPEDIDOS.svg"),
          activeImgUrl: require("../../assets/user-img/menu/GESTIONDEPEDIDOS_1.svg"),
          arr: [
            {
              id: "3",
              name: this.$fanyi("正在进行的订单"),
              url: "userOrder",
              activeStatus: false,
              count: 1,
            },
            {
              id: "4",
              name: this.$fanyi("问题产品"),
              url: "issueOrder",
              count: 2,
              activeStatus: false,
            },
            {
              id: "5",
              name: this.$fanyi("订单到达RAKUMART仓库"),
              url: "warehouse",
              count: 3,
              activeStatus: false,
            },
            {
              id: "6",
              name: this.$fanyi("交货订单"),
              url: "wuliuStatus",
              count: 4,
              activeStatus: false,
            },
            // {
            //   id: "7",
            //   name: this.$fanyi("已完成订单"),
            //   url: "internationalLogistics",
            //   count: 5,
            //   activeStatus: false,
            // },
          ],
        },
        {
          id: "8",
          name: this.$fanyi("发票"),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require("../../assets/user-img/menu/FACTURACION.svg"),
          activeImgUrl: require("../../assets/user-img/menu/FACTURACION_1.svg"),
          arr: [
            {
              id: "9",
              name: this.$fanyi("历史发票"),
              url: "invoiceHistory",
              count: 6,
              activeStatus: false,
            },
            {
              id: "10",
              name: this.$fanyi("发票信息"),
              url: "invoiceBillingData",
              activeStatus: false,
            },
          ],
        },
        {
          id: "11",
          name: this.$fanyi("收藏夹"),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require("../../assets/user-img/menu/FAVORITOS.svg"),
          activeImgUrl: require("../../assets/user-img/menu/FAVORITOS_1.svg"),
          arr: [
            {
              id: "12",
              name: this.$fanyi("收藏商品"),
              url: "collection",
              activeStatus: false,
              count: 7,
            },
            {
              id: "13",
              name: this.$fanyi("店铺收藏"),
              url: "favoritec",
              activeStatus: false,
            },
            {
              id: "14",
              name: this.$fanyi("商品库"),
              url: "commodity",
              activeStatus: false,
            },
          ],
        },
        {
          id: "15",
          name: this.$fanyi("个人资料"),
          activeStatus: false,
          downIconStatus: true,
          imgUrl: require("../../assets/user-img/menu/DATOSPERSONALES.svg"),
          activeImgUrl: require("../../assets/user-img/menu/DATOSPERSONALES_1.svg"),
          arr: [
            {
              id: "16",
              name: this.$fanyi("修改个人信息"),
              url: "userDetail",
              activeStatus: false,
            },
            {
              id: "17",
              name: this.$fanyi("发货地址"),
              url: "deliverySite",
              activeStatus: false,
            },
            {
              id: "18",
              name: this.$fanyi("密码修改与验证"),
              url: "changepassworld",
              query: { type: "password" },
              activeStatus: false,
            },
          ],
        },
      ],
      countNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderOffer: 0, //待支付订单
        orderWaitPay: 0,
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
      },
      newCountNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderWaitPay: 0,
        orderOffer: 0, //待支付订单
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
      },
      myPage: {
        balance: 0,
        order: {},
        pic: "",
        point: "",
        porder: {},
        user_id: "",
        user_name: "",
      },
    };
  },
  components: {
    Foot,
    HomePageTop,
    countTo,
  },
  computed: {
    activePage() {
      return this.$route.name;
    },
    sidebarNumList() {
      return this.$store.state.sidebarNumList;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    ordersSumList() {
      return this.$store.state.ordersSumList;
    },
  },
  created() {
    this.getWarehouseOrderCountNum();
    this.$store.dispatch("getLeftSidebarNum");
    this.$store.dispatch("sidebarNumList");
    this.$store.dispatch("getOrdersSum");
    // this.getMyPage();
    this.getNoticeUnreadNoticeCount();
    this.getHeadCountNum();
    if (this.$route.name === "userIndex") {
      this.activeMenu[0].activeStatus = true;
    } else {
      for (let i = 0; i < this.activeMenu.length; i++) {
        if (this.activeMenu[i].arr != undefined) {
          for (let j = 0; j < this.activeMenu[i].arr.length; j++) {
            if (this.activeMenu[i].arr[j].url === this.$route.name) {
              this.activeMenu[i].activeStatus = true;
              this.activeMenu[i].downIconStatus = true;
              this.activeMenu[i].arr[j].activeStatus = true;
              break;
            }
          }
        }
      }
    }
  },
  mounted() {},
  methods: {
    beforeAvatarUpload(file) {
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      //console.log(file.type);
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("头像上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    handleAvatarSuccess(res, file) {
      this.$api.userPortraitSave({ head_img: res.data }).then((res) => {
        //console.log("userPortraitSave", res);
        if (res.code != 0) return;
        this.$api.EuropegetUserInfo().then((res) => {
          this.$store.commit("userData", res.data);
          this.loading.close();
          this.$forceUpdate();
        });
      });
    },
    listenSignStatus(msg) {
      this.getWarehouseOrderCountNum();
      this.$store.dispatch("getLeftSidebarNum");
      this.$store.dispatch("sidebarNumList");
      this.$store.dispatch("getOrdersSum");
      // this.getMyPage();
      this.getNoticeUnreadNoticeCount();
      this.getHeadCountNum();
      for (let i = 0; i < this.activeMenu.length; i++) {
        if (this.activeMenu[i].arr != undefined) {
          for (let j = 0; j < this.activeMenu[i].arr.length; j++) {
            if (this.activeMenu[i].arr[j].url === this.$route.name) {
              this.activeMenu[i].activeStatus = true;
              this.activeMenu[i].downIconStatus = true;
              this.activeMenu[i].arr.forEach((item, index) => {
                item.activeStatus = j === index;
              });
              break;
            } else {
              this.activeMenu[i].activeStatus = false;
              this.activeMenu[i].downIconStatus = false;
              this.activeMenu[i].arr[j].activeStatus = false;
            }
          }
        } else {
          this.activeMenu[0].activeStatus = false;
        }
      }
    },
    getNoticeUnreadNoticeCount() {
      //favoriteGoodsList
      this.$api
        .noticeUnreadNotice()
        .then((res) => {
          this.count = res.data.length;
          this.$api
            .favoriteGoodsList({
              page: 1,
              pageSize: 99999999999999999999999999999999999999999999999999999,
            })
            .then((res) => {
              this.favoriteGoodsCount = res.data.data.length;
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    },
    // 获取仓库顶部数据的数量
    getWarehouseOrderCountNum() {
      this.$api.warehouseOrderCountNum().then((res) => {
        // this.tableTypeNum=res.data;
        res.data.forEach((item) => {
          switch (item.status) {
            case "全部状态":
              this.warehouseOrderNum = item.num;
              break;
          }
        });
      });
    },
    //获取个人中心看板统计头部数据
    getHeadCountNum() {
      this.$api
        .headCountNum()
        .then((res) => {
          this.newCountNumInfo = res.data;
        })
        .catch((err) => {});
    },
    //更新选中的菜单样式
    updateSelectMenu(name, status) {
      this.activeMenu.forEach((item) => {
        if (
          item.name === name &&
          item.activeStatus === false &&
          item.downIconStatus === false
        ) {
          if (status === 1) {
            this.$router.push({ name: item.url });
          }
          item.activeStatus = true;
          item.downIconStatus = true;
        } else if (
          (item.name === name && item.activeStatus === true) ||
          (item.name === name &&
            item.activeStatus === false &&
            item.downIconStatus === true)
        ) {
          item.activeStatus = false;
          item.downIconStatus = false;
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false;
            });
          }
        } else if (item.name !== name && item.activeStatus === true) {
          item.activeStatus = false;
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false;
            });
          }
        }
      });
    },
    //更新子菜单的选中项
    updateSelectChildrenMenu(index, index1) {
      // this.activeMenu[index].arr[index1].activeStatus=true;
      this.activeMenu.forEach((item, menuIndex) => {
        if (menuIndex === index) {
          this.activeMenu[menuIndex].activeStatus = true;
          this.activeMenu[menuIndex].downIconStatus = true;
          item.arr.forEach((items, menuSelectIndex) => {
            items.activeStatus = index1 === menuSelectIndex;
          });
        } else {
          this.activeMenu[menuIndex].activeStatus = false;
          if (item.arr != undefined) {
            item.arr.forEach((items) => {
              items.activeStatus = false;
            });
          }
        }
      });
    },
    poundagePageJump() {
      let routeUrl = router.resolve({
        path: "/comisiones-rakumart",
      });
      window.open(routeUrl.href);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin";

.index {
  margin: 0 auto;
}

.rowAndCenter {
  display: flex;
  align-items: center;
}

.userCenter {
  width: 1400px;
  margin: 0 auto 30px;

  //头部
  .userindexhead {
    width: 100%;
    height: 140px;
    margin: 20px 0;
  }

  //内容
  .userindexBody {
    display: flex;
    align-items: flex-start;
    width: 320px;

    .leftUserMessageContainer {
      .userMessageHeaderContainer {
        .userMessageContainerTop {
          display: flex;
          align-items: center;
          padding: 20px 27px;
          $userAvatar: 52px;
          .upload-demo {
            position: relative;
            &:hover .showDow {
              opacity: 1;
            }
            .showDow {
              background-color: rgba($color: #000000, $alpha: 0.5);
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              opacity: 0;
              transition: 0.3s;
              top: 0;
              left: -40px;
              border-radius: 50%;
              width: $userAvatar;
              margin: 0 30px 0 40px;
              height: $userAvatar;
              color: white;
              font-size: 12px;
            }
          }
          img {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            margin-right: 16px;
          }

          .userName {
            .top {
              font-size: 18px;
              padding: 0;
              display: flex;
              align-items: center;

              div:last-child {
                font-weight: bold;
              }
            }

            .bottom {
              font-size: 14px;
            }
          }
        }

        .userMessageContainerBottom {
          width: 319px;
          height: 54px;
          background: #eeeffd;
          border: 1px solid #e3e3e3;
          border-radius: 6px;
          margin-bottom: 29px;
          padding: 13px 13px 13px 19px;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          .userMessageBottomContainerLeft {
            display: flex;
            align-items: center;

            div {
              margin-left: 11px;
            }
          }

          .userMessageBottomContainerRight {
            width: 29px;
            height: 29px;
            background: #2c4bdf;
            border-radius: 4px;
            color: #fff;
            font-size: 14px;
            line-height: 29px;
            text-align: center;
          }
        }
      }

      .menuBar {
        width: 320px;
        margin-right: 20px;
        min-height: 700px;
        // 菜单内容左间距
        $fontMarginLeft: 30px;
        .el-submenu__icon-arrow {
          transition: 0.3s;
        }
        .beDown {
          rotate: 90deg;
        }
        > div {
          p {
            margin-bottom: -1px;
            width: 100%;
            height: 60px;
            font-size: 20px;
            line-height: 60px;

            font-weight: bold;
            padding-left: $fontMarginLeft;

            box-sizing: border-box;

            &.titleBox {
              position: relative;

              img {
                position: absolute;
                left: 12px;
                top: 22px;
                width: 16px;
                height: 16px;

                &.sec {
                  display: none;
                }
              }

              &:hover {
                img {
                  &.sec {
                    display: inline-block !important;
                  }
                }
              }
            }
          }

          span {
            display: block;
            width: 100%;
            height: 60px;
            font-size: 13px;
            line-height: 60px;
            padding-left: 20px;
            box-sizing: border-box;
            cursor: pointer;

            &.active {
              color: $subjectColor;
              background-color: #f0f1ff;
            }

            //&:hover {
            //  background-color: #f0f1ff;
            //}
          }

          .menuTitle {
            position: relative;
            color: #2f2f2f;
            font-size: 18px;

            img {
              width: 19px;
              height: 17px;
            }

            /deep/ .el-icon-arrow-right,
            /deep/ .el-icon-arrow-down {
              font-size: 16px;
              font-weight: bold;
            }
          }

          .activeMenuTitle {
            position: relative;
            color: #fff;
            background: #1e2997;
            font-size: 18px;
            border-radius: 6px;
            ///deep/ .el-icon-arrow-right {
            //  font-size: 16px;
            //  font-weight: bold;
            //  color: #fff;
            //  transform: rotateZ(90deg);
            //}
            img {
              width: 19px;
              height: 17px;
            }

            /deep/ .el-icon-arrow-down {
              font-size: 16px;
              font-weight: bold;
              color: #fff;
            }
          }

          .menuChildrenList {
            transition: height 0.3s;
            overflow: hidden;
            .noActiveMenuChildrenContainer {
              justify-content: space-between;
              margin: 13px 10px 0;
              padding-right: 7px;
              padding-left: 39px;
              cursor: pointer;
              width: 300px;
              height: 45px;

              .childrenName {
                color: #535353;
              }

              .orderCountContainer {
                width: 30px;
                height: 30px;
                background: #e9edff;
                border-radius: 4px;
                color: #1e2997;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
              }
            }

            .activeMenuChildrenContainer {
              justify-content: space-between;
              margin: 13px 10px 0;
              padding-right: 7px;
              padding-left: 39px;
              cursor: pointer;
              width: 300px;
              height: 45px;
              background: #dfe4ff;
              border-radius: 6px;

              .childrenName {
                color: #2f2f2f;
              }

              .orderCountContainer {
                width: 30px;
                height: 30px;
                background: #1e2997;
                border-radius: 4px;
                color: #fff;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
              }
            }
          }
          .menuChildrenList.close {
            height: 0 !important;
          }
        }
      }
    }

    .contentBar {
      flex: 0 0 1060px;
      //background-color: white;
      .header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        .left {
          width: 410px;
          height: 254px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 6px;
          margin-right: 30px;

          .title {
            height: 48px;
            line-height: 48px;
            padding-left: 35px;
            font-size: 18px;
            border-bottom: 1px solid #e3e3e3;
          }

          .content {
            padding: 20px 30px 30px;

            .top {
              display: flex;
              align-items: center;

              .left,
              .right {
                width: 166px;
                height: 84px;
                background: #ffffff;
                border: 1px solid #e3e3e3;
                border-radius: 6px;
                padding: 10px;
                cursor: pointer;

                div:first-child {
                  color: #2f2f2f;
                  font-weight: 600;
                  font-size: 28px;
                }

                div:last-child {
                  color: #7a7a7a;
                  font-size: 14px;
                }
              }
            }

            .bottom {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 20px;

              .bottomLeft {
                display: flex;
                align-items: center;

                .level {
                  width: 32px;
                  height: 32px;
                  background-color: white;
                  text-align: center;
                  line-height: 24px;
                  font-size: 12px;
                  margin-right: 10px;

                  div {
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }

                .levelMessage {
                  .levelMessageTop {
                    font-size: 14px;
                    margin-bottom: 10px;
                  }

                  .levelMessageCenter {
                    width: 221px;
                    height: 8px;
                    border: 1px solid #e3e3e3;
                    border-radius: 3px;
                    margin-bottom: 5px;
                    overflow: hidden;

                    div {
                      width: 55%;
                      height: 100%;
                      background: #e3e3e3;
                      border-radius: 3px 0 0 3px;
                    }
                  }

                  /deep/ .el-link {
                    font-size: 12px;
                  }
                }
              }

              .cuponesNumberContainer {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                cursor: pointer;

                div:first-child {
                  font-size: 14px;
                  margin-bottom: 8px;
                }

                div:last-child {
                  font-weight: 600;
                  font-size: 18px;
                }
              }
            }
          }
        }

        .right {
          width: 612px;
          height: 254px;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          border-radius: 6px;

          .title {
            height: 48px;
            line-height: 48px;
            padding-left: 35px;
            font-size: 18px;
            border-bottom: 1px solid #e3e3e3;
          }

          .content {
            padding: 20px 30px;

            .orderNumberContainer {
              width: 552px;
              height: 48px;
              border-radius: 10px;
              line-height: 48px;
              padding: 8px 30px 7px 8px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .manageContainer {
              display: flex;
              align-items: center;
              cursor: pointer;

              .text {
                color: #1e2997;
                font-size: 14px;
                margin-right: 4px;
              }

              .el-icon-arrow-right:before {
                color: #1e2997;
                font-size: 20px;
              }
            }

            .numberBackground {
              width: 32px;
              height: 32px;
              border-radius: 6px;
              color: #fff;
              line-height: 32px;
              text-align: center;
              margin-right: 10px;
            }

            .greenBackground {
              background: #eaffef;
            }

            .blueBackground {
              background: #e7f5ff;
            }

            .yellowBackground {
              background: #fee6cd;
            }

            .icon {
              width: 11px;
              height: 11px;
              border-radius: 50%;
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>
